import React, {
  useContext,
} from 'react';

import { Menu } from '@tmap/mmm-style-guide/src/Menu';
import { styled } from '@tmap/mmm-style-guide/src/styled';
import { Box } from '@tmap/mmm-style-guide/src/Box';

import HeaderContext from './HeaderContext';
import RelocNavGroup from './relocNavGroup';
import { Logout } from './authButtons';

const StyledMenu = styled(Menu)(() => ({
  '& .MuiPaper-root': {
    minWidth: '200px',
  },
}));

export default function HeaderMenu() {
  const {
    menuAnchor,
    isMenuOpen,
    handleCloseMenu,
    headerMenuSx,
    isAuthenticated,
  } = useContext(HeaderContext);
  return (
    <StyledMenu
      anchorEl={menuAnchor}
      keepMounted
      open={isMenuOpen}
      onClose={handleCloseMenu}
      sx={headerMenuSx}
    >
      <RelocNavGroup location='first' />
      <RelocNavGroup location='browse' />
      <RelocNavGroup location='communities' />
      <RelocNavGroup location='resources' />
      <RelocNavGroup location='final' />
      {isAuthenticated && (
        <Box paddingX={2} paddingTop={3} paddingBottom={1}>
          <Logout />
        </Box>
      )}
    </StyledMenu>
  );
}
