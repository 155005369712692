import { useAuth0 } from '@auth0/auth0-react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

const useRequireAuth = () => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  const hasAuthToken = useSelector((state) => Boolean(state?.authStore?.id_token));
  const router = useRouter();

  /**
   * @param { () => any } authFunction
   * - function to call if user is authenticated
   * @param { object } options
   * @param { string } [options.returnTo]
   * - Url to return the user to upon successful authentication.
   * @param { 'login'|'signup'} [options.screenHint]
   * - Tells auth0 to display login or signup screen by default.
   * @param { string } [options.loginHint]
   * - If we know the user's email we can pass it to auth0 to autofill the email field.
   */
  function requireAuth(authFunction, options = {}) {
    const { returnTo, screenHint = 'signup', loginHint } = options;

    if (!isAuthenticated) {
      loginWithRedirect({
        appState: {
          returnTo: returnTo || router.asPath,
        },
        authorizationParams: {
          screen_hint: screenHint,
          login_hint: loginHint,
        },
      });
    } else authFunction();
  }

  /**
   * @param { string } [url]
   * - Url to redirect to. Defaults to current path if empty.
   * @param { object } options
   * @param { 'login'|'signup'} [options.screenHint]
   * - Tells auth0 to display login or signup screen by default.
   * @param { string } [options.loginHint]
   * - If we know the user's email we can pass it to auth0 to autofill the email field.
   */
  function requireAuthRedirect(url, options = {}) {
    const { screenHint = 'signup', loginHint } = options;
    if (!isAuthenticated) {
      loginWithRedirect({
        appState: {
          returnTo: url || router.asPath,
        },
        authorizationParams: {
          screen_hint: screenHint,
          login_hint: loginHint,
        },
      });
    } else router.push(url || router.asPath);
  }

  return {
    isAuthenticated,
    isLoading,
    hasAuthToken,
    requireAuth,
    requireAuthRedirect,
  };
};

export default useRequireAuth;
