import React from 'react';
import locationLabelText from '@tmap/mmm-core/locationLabelText';

export const headerMenuSx = ({ hasCommunity }) => (
  hasCommunity
    ? { display: { xs: 'none' } }
    : { display: { xs: 'none', md: 'unset' } }
);

export const headerDrawerSx = ({ hasCommunity }) => (
  hasCommunity
    ? { display: {} }
    : { display: { md: 'none' } }
);

export const navLinks = ({
  hasCommunity,
  myCommunities,
  isCommunityOwner,
}) => ({
  home: {
    label: 'Home',
    button: {
      href: '/',
    },
    relocation: { xs: 'first' },
  },
  offers: {
    label: 'Programs',
    button: {
      href: '/get-paid',
    },
    relocation: hasCommunity ? {
      xs: 'browse',
      lg: 'main',
    } : {
      xs: 'browse',
      md: 'main',
    },
  },
  communities: {
    label: 'Communities',
    button: {
      href: '/moving-living',
    },
    relocation: hasCommunity ? {
      xs: 'browse',
      lg: 'main',
    } : {
      xs: 'browse',
      md: 'main',
    },
  },
  ...Object.fromEntries(myCommunities.map((c, i) => ([
    `my-community-${c._id}`,
    {
      label: locationLabelText(c.locationText, c.regionText, c.locationGeo?.state_abbreviation),
      button: {
        href: `/moving-living/${c.slug.current}`,
      },
      relocation: {
        xs: 'final',
        md: i === 0 ? 'alt' : 'final',
      },
    },
  ]))),
  articles: {
    label: 'Articles',
    button: {
      href: '/articles',
    },
    relocation: { xs: 'resources', lg: 'main' },
  },
  howItWorks: {
    label: 'How it Works',
    button: {
      href: '/how-it-works',
    },
    relocation: { xs: 'resources', lg: 'main' },
  },
  news: {
    label: 'News + Press',
    button: {
      href: '/news',
    },
    relocation: { xs: 'resources' },
  },
  forEconDevs: {
    label: ['For Economic', <br key='space' />, 'Developers'],
    button: {
      href: '/community-articles',
    },
    relocation: isCommunityOwner ? { xs: 'resources' } : {},
  },
  profile: {
    label: 'My Profile',
    button: {
      href: '/myprofile',
      activeHighlightPattern: (h) => `^${h}((/[a-zA-Z-]+)+)?([#?].*)?$`,
    },
    relocation: { xs: 'final' },
    auth: (a) => Boolean(a),
  },
});

export const navGroups = ({ navLinks: links, isAuthenticated }) => {
  const bks = ['xs', 'sm', 'md', 'lg', 'xl'];
  const groups = {
    main: { label: null, show: {} },
    alt: { label: null, show: {} },
    first: { label: null, show: {} },
    browse: { label: 'BROWSE', show: {} },
    communities: { label: 'MY COMMUNITIES', show: {} },
    resources: { label: 'NEWS & RESOURCES', show: {} },
    final: { label: 'ACCOUNT', show: {} },
  };
  // compute the whole group visibility using items visiblities
  Object.keys(groups).forEach((location) => {
    const groupIsVisible = Object.fromEntries(bks.map((bk) => [bk, false]));
    Object.values(links)
      .forEach(({ auth = () => true, relocation }) => {
        if (!auth(isAuthenticated)) return;
        const itemIsVisible = {};
        bks.forEach((bk) => {
          if (!(bk in relocation)) return;
          const isVisibleAtBreakpoint = relocation[bk] === location;
          for (let i = bks.indexOf(bk); i < bks.length; i++) {
            itemIsVisible[bks[i]] = isVisibleAtBreakpoint;
          }
        });
        bks.forEach((bk) => {
          if (itemIsVisible[bk]) groupIsVisible[bk] = true;
        });
      });
    groups[location].show = groupIsVisible;
  });
  return groups;
};
