import React, {
  useContext,
} from 'react';

import { Grid } from '@tmap/mmm-style-guide/src/Grid';
import { Button } from '@tmap/mmm-style-guide/src/Button';
import { styled } from '@tmap/mmm-style-guide/src/styled';

import HeaderContext from './HeaderContext';

const SignUpButton = styled(Button)(() => ({
  '& .MuiButton-root': {
    whiteSpace: 'nowrap',
  },
}));

const SignInButton = styled(Button)(() => ({
  '& .MuiButton-root': {
    whiteSpace: 'nowrap',
  },
}));

const LogOutButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  '& .MuiButton-root': {
    whiteSpace: 'nowrap',
  },
}));

export function SignInSignUp() {
  const {
    handleSignUp,
    handleSignIn,
  } = useContext(HeaderContext);
  return (
    <Grid
      container
      flexWrap='nowrap'
      justifyContent='center'
      alignItems='center'
      spacing={1.5}
    >
      <Grid item xs>
        <SignUpButton
          fullWidth
          disableElevation
          variant='contained'
          color='convert'
          onClick={handleSignUp}
        >
          Sign up
        </SignUpButton>
      </Grid>
      <Grid item xs>
        <SignInButton
          fullWidth
          variant='outlined'
          color='convert'
          onClick={handleSignIn}
        >
          Sign in
        </SignInButton>
      </Grid>
    </Grid>
  );
}

export function Logout() {
  const {
    handleLogout,
  } = useContext(HeaderContext);
  return (
    <Grid
      container
      flexWrap='nowrap'
      justifyContent='center'
      alignItems='center'
      spacing={2}
    >
      <Grid item xs>
        <LogOutButton
          fullWidth
          variant='outlined'
          color='inherit'
          onClick={handleLogout}
        >
          Logout
        </LogOutButton>
      </Grid>
    </Grid>
  );
}
