import React from 'react';
import { useRouter } from 'next/router';
import { Typography } from '@tmap/mmm-style-guide/src/Typography';
import { Grid } from '@tmap/mmm-style-guide/src/Grid';

import LinkedMenuItem from '../linkedMenuItem';

export default function NavButton(props) {
  const {
    href,
    disableActiveHighlight = false,
    activeHighlightPattern = (h) => `^${h}([#?].*)?$`,
    children,
    location,
    ...otherProps
  } = props;
  const router = useRouter();
  const isActive = !disableActiveHighlight
    && new RegExp(activeHighlightPattern(href)).test(router.asPath);
  const typog = {
    variant: 'body1',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    whiteSpace: 'nowrap',
    color: 'text.secondary',
  };

  return (
    <LinkedMenuItem
      IconProps={isActive ? { variant: 'contained', color: 'secondary' } : undefined}
      href={href}
      {...otherProps}
    >
      <Grid container justifyContent='start'>
        <Grid item>
          <Grid container position='relative' justifyContent='center'>
            <Grid item>
              <Typography
                {...typog}
                color='secondary.main'
                fontWeight={600}
                visibility={isActive ? 'visible' : 'hidden'}
              >
                {children}
              </Typography>
            </Grid>
            <Grid item position='absolute'>
              <Typography
                {...typog}
                color={['main', 'alt'].includes(location) ? 'text.primary' : 'text.secondary'}
                visibility={!isActive ? 'visible' : 'hidden'}
              >
                {children}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </LinkedMenuItem>
  );
}
