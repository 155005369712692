import { createClient } from '@sanity/client';
import config from './sanity';

let sanityClient = null;
try {
  sanityClient = createClient(config.api);
} catch (e) {
  console.log(e);
}

const client = sanityClient;
export default client;
