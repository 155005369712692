import makeView from '@tmap/mmm-core/redux/views/makeView';
import makeThunktankAction from '@tmap/mmm-core/redux/thunktank/makeThunktankAction';
import { fromKey, types as EntityKey } from '@tmap/mmm-core/entityKey';
import { getClient } from '../lib/apiClient';
import { COMMUNITY_MEMBERS_CACHE } from './cacheIndex';
import client from '../client';

const ENTITLED_COMMUNITY_ROLES = [
  EntityKey.CONTRIBUTOR,
  EntityKey.MODERATOR,
  EntityKey.OWNER,
];

export const getCommunityMembers = makeView(
  async (communityId) => {
    const res = await getClient(process.env.NEXT_PUBLIC_API_DOMAIN).get(`/api/v1/communities/${communityId}/moderators`);
    return ({
      // our view/cache interactions assume each record as an _id field
      docs: res.data?.communityMembers?.map((mem) => ({
        _id: mem.userId,
        ...mem,
      })),
    });
  },
).withCache(COMMUNITY_MEMBERS_CACHE);

export const updateMemberRoles = makeThunktankAction(() => (
  async (communityId, updates) => {
    const docs = await Promise.all(updates.map(async ([user, role]) => {
      await getClient(process.env.NEXT_PUBLIC_API_DOMAIN).post(`/api/v1/communities/${communityId}/moderators/upsert`, {
        userId: user.userId,
        role,
      });
      return { ...user, role };
    }));
    return { docs };
  }))
  .withStore().withCache(COMMUNITY_MEMBERS_CACHE).withErrors({
    all: 'There was an error updating member status. Please try again later.',
  });

export const removeMemberRole = makeThunktankAction(async (communityId, user) => {
  await getClient(process.env.NEXT_PUBLIC_API_DOMAIN).post(`/api/v1/communities/${communityId}/moderators/remove`, {
    userId: user.userId,
  });
  return { _id: user.userId };
}).withErrors({
  all: 'There was an error updating member status. Please try again later.',
});

export const getCommunitiesOfMember = makeView(
  async (user) => {
    const idSet = new Set();
    (user?.roles || []).forEach((role) => {
      const { type, id } = fromKey(role);
      if (ENTITLED_COMMUNITY_ROLES.includes(type)) {
        idSet.add(id);
      }
    });
    const ids = Array.from(idSet);
    const { communities } = await client.fetch(`{
        'communities': *[_type == 'community' && _id in $ids]{
          _id,
          slug,
          locationText,
          regionText,
          locationGeo,
        }
      }`, { ids });
    return {
      docs: communities.sort(({ _id: a }, { _id: b }) => ids.indexOf(a) - ids.indexOf(b)),
    };
  },
);
