import React, {
  useContext,
} from 'react';

import { styled } from '@tmap/mmm-style-guide/src/styled';
import { Grid } from '@tmap/mmm-style-guide/src/Grid';
import { Typography } from '@tmap/mmm-style-guide/src/Typography';

import HeaderContext from './HeaderContext';
import NavButton from './navButton';

const RelocGroupGrid = styled(Grid, {
  shouldForwardProp: (p) => !['location', 'show'].includes(p),
})(({
  theme,
  show,
}) => ({
  display: 'none',
  ...Object.fromEntries(Object.entries(show).map(([bk, sh]) => ([
    theme.breakpoints.up(bk),
    { display: sh ? 'block' : 'none' },
  ]))),
}));

const RelocNavGrid = styled(Grid, {
  shouldForwardProp: (p) => !['location', 'relocation'].includes(p),
})(({
  theme,
  location,
  relocation,
}) => ({
  display: 'none',
  ...Object.fromEntries(Object.entries(relocation).map(([bk, loc]) => ([
    theme.breakpoints.up(bk),
    { display: loc === location ? 'block' : 'none' },
  ]))),
}));

export default function RelocNavGroup(props) {
  const {
    noLabel = false,
    location,
  } = props;
  const {
    navLinks,
    navGroups,
    isAuthenticated,
  } = useContext(HeaderContext);
  const { label: groupLabel, show } = navGroups[location];

  return (
    <RelocGroupGrid item show={show}>
      <Grid container direction='column'>
        {(!noLabel && groupLabel) && (
          <Grid item display='flex'>
            <Typography
              variant='overline'
              color='text.disabled'
              lineHeight='32px'
              letterSpacing='1px'
              fontSize='12px'
              paddingLeft={2}
              paddingTop={2}
              paddingBottom={1}
            >
              {groupLabel}
            </Typography>
          </Grid>
        )}
        {
          Object.entries(navLinks)
            .filter(([, { auth = () => true }]) => auth(isAuthenticated))
            .map(([key, { relocation, label, button }]) => (
              <RelocNavGrid
                item
                key={`reloc-${location}-${key}`}
                location={location}
                relocation={relocation}
              >
                <NavButton location={location} {...button}>
                  {label}
                </NavButton>
              </RelocNavGrid>
            ))
        }
      </Grid>
    </RelocGroupGrid>
  );
}
