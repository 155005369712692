import React from 'react';
import NavListItem from '@tmap/mmm-style-guide/src/NavListItem';
import Link from 'next/link';

const LinkedMenuItem = React.forwardRef((props, ref) => {
  const {
    href, children, ...otherProps
  } = props;
  return (
    <Link href={href}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a tabIndex={-1}>
        <NavListItem {...otherProps} ref={ref}>{children}</NavListItem>
      </a>
    </Link>
  );
});

export default LinkedMenuItem;
