import React, { useState } from 'react';

import { shallowEqual, useSelector } from 'react-redux';

import { Avatar } from '@tmap/mmm-style-guide/src/Avatar';
import { Person } from '@tmap/mmm-style-guide/src/Icon';
import { styled } from '@tmap/mmm-style-guide/src/styled';

import ResponsivePicture from './responsivePicture';

const StyledAvatar = styled(Avatar, {
  shouldForwardProp: (p) => !['size', 'centered', 'borderColor', 'borderThickness', 'loaded'].includes(p),
})(({
  theme,
  size,
  centered,
  borderColor,
  borderThickness,
  loaded,
}) => ({
  width: `${size}px`,
  height: `${size}px`,
  position: 'relative',
  zIndex: 0,
  ...(centered ? {
    margin: 'auto',
  } : {}),
  ...(loaded ? {
    background: 'transparent',
  } : {}),
  // https://stackoverflow.com/a/51496341
  ...(borderColor ? {
    '&::before': {
      content: '""',
      position: 'absolute',
      zIndex: -1,
      inset: 0,
      background: typeof borderColor === 'function'
        ? borderColor((theme.vars || theme).palette)
        : `linear-gradient(45deg, ${theme.palette.primary.main} 10%, ${theme.palette.aqua.light} 90%)`,
      padding: borderThickness,
      borderRadius: '50%',
      mask: 'linear-gradient(#000 0 0) content-box, linear-gradient(#000 0 0)',
      maskComposite: 'exclude',
      WebkitMaskComposite: 'xor',
    },
  } : {}),
}));

function ProfileAvatar(props) {
  const {
    size,
    centered,
    user,
    borderColor,
    borderThickness = '4%',
  } = props;

  const [loaded, setLoaded] = useState(false);
  const currentUser = useSelector((state) => state?.users?.current, shallowEqual);

  const computedUser = user || currentUser;

  return (
    <StyledAvatar
      centered={centered}
      size={size}
      borderColor={borderColor}
      borderThickness={borderThickness}
      loaded={loaded}
    >
      {computedUser && computedUser.profileImage ? (
        <ResponsivePicture
          image={computedUser.profileImage}
          onLoad={() => setLoaded(true)}
          style={{
            zIndex: -2,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            border: '1px solid transparent',
          }}
          width={size}
          height={size}
        />
      ) : (
        <Person sx={{ fontSize: `${size * 0.6}px` }} />
      )}
    </StyledAvatar>
  );
}

export default ProfileAvatar;
